
import * as directusRuntime$AlldBUOtn4 from '/opt/buildhome/repo/node_modules/.pnpm/@nuxt+image@1.9.0_db0@0.2.4_ioredis@5.5.0_magicast@0.3.5/node_modules/@nuxt/image/dist/runtime/providers/directus'

export const imageOptions = {
  "screens": {
    "xs": 320,
    "sm": 640,
    "md": 768,
    "lg": 1024,
    "xl": 1280,
    "xxl": 1536,
    "2xl": 1536
  },
  "presets": {},
  "provider": "directus",
  "domains": [],
  "alias": {},
  "densities": [
    1,
    2
  ],
  "format": [
    "webp"
  ],
  "quality": 80
}

imageOptions.providers = {
  ['directus']: { provider: directusRuntime$AlldBUOtn4, defaults: {"baseURL":"https://admin.ics-mfg.com/assets/"} }
}
        