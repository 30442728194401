import revive_payload_client_K3div32WVW from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.2_db0@0.2.4_eslint@9.20.1_jiti@2.4.2__ior_2a2tyvspcsgefln7irzjunflme/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_FY1bkVf1gj from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.2_db0@0.2.4_eslint@9.20.1_jiti@2.4.2__ior_2a2tyvspcsgefln7irzjunflme/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_BjMqZ3wmZU from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.2_db0@0.2.4_eslint@9.20.1_jiti@2.4.2__ior_2a2tyvspcsgefln7irzjunflme/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_mGEn9J3WrD from "/opt/buildhome/repo/node_modules/.pnpm/nuxt-site-config@3.0.6_magicast@0.3.5_vite@6.1.0_@types+node@22.13.2_jiti@2.4.2_terser@5.39.0_s5gavmzeauady6sikjrdkqrg5y/node_modules/nuxt-site-config/dist/runtime/app/plugins/0.siteConfig.js";
import payload_client_9VCsesUMLh from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.2_db0@0.2.4_eslint@9.20.1_jiti@2.4.2__ior_2a2tyvspcsgefln7irzjunflme/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_8jOIJj0jfU from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.2_db0@0.2.4_eslint@9.20.1_jiti@2.4.2__ior_2a2tyvspcsgefln7irzjunflme/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_b5ZMMOd2C1 from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.2_db0@0.2.4_eslint@9.20.1_jiti@2.4.2__ior_2a2tyvspcsgefln7irzjunflme/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_B1dIgHpd4m from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.2_db0@0.2.4_eslint@9.20.1_jiti@2.4.2__ior_2a2tyvspcsgefln7irzjunflme/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_4ZxH31EjIl from "/opt/buildhome/repo/node_modules/.cache/nuxt/.nuxt/modules/@nuxt-scripts/plugin.mjs";
import components_plugin_zlvi6dcIsi from "/opt/buildhome/repo/node_modules/.cache/nuxt/.nuxt/components.plugin.mjs";
import prefetch_client_wQAreqkmTj from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.2_db0@0.2.4_eslint@9.20.1_jiti@2.4.2__ior_2a2tyvspcsgefln7irzjunflme/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import siteConfig_oJx4XA0JB2 from "/opt/buildhome/repo/node_modules/.pnpm/nuxt-seo-utils@6.0.8_magicast@0.3.5_rollup@4.34.6_vite@6.1.0_@types+node@22.13.2_jiti@2.4.2_t_rtfwe433impedgzebaxehobqqq/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/siteConfig.js";
import inferSeoMetaPlugin_eONTQRbQpT from "/opt/buildhome/repo/node_modules/.pnpm/nuxt-seo-utils@6.0.8_magicast@0.3.5_rollup@4.34.6_vite@6.1.0_@types+node@22.13.2_jiti@2.4.2_t_rtfwe433impedgzebaxehobqqq/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/inferSeoMetaPlugin.js";
import titles_Z2HrhC08t2 from "/opt/buildhome/repo/node_modules/.pnpm/nuxt-seo-utils@6.0.8_magicast@0.3.5_rollup@4.34.6_vite@6.1.0_@types+node@22.13.2_jiti@2.4.2_t_rtfwe433impedgzebaxehobqqq/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/titles.js";
import plugin_client_fxr5QpR3De from "/opt/buildhome/repo/node_modules/.pnpm/@nuxtjs+color-mode@3.5.2_magicast@0.3.5/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import unocss_RAHspWIKy3 from "/opt/buildhome/repo/node_modules/.cache/nuxt/.nuxt/unocss.mjs";
import errors_hdY7q775fW from "/opt/buildhome/repo/app/plugins/errors.ts";
import defaults_4dOom1KMuO from "/opt/buildhome/repo/node_modules/.pnpm/nuxt-seo-utils@6.0.8_magicast@0.3.5_rollup@4.34.6_vite@6.1.0_@types+node@22.13.2_jiti@2.4.2_t_rtfwe433impedgzebaxehobqqq/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/defaults.js";
export default [
  revive_payload_client_K3div32WVW,
  unhead_FY1bkVf1gj,
  router_BjMqZ3wmZU,
  _0_siteConfig_mGEn9J3WrD,
  payload_client_9VCsesUMLh,
  navigation_repaint_client_8jOIJj0jfU,
  check_outdated_build_client_b5ZMMOd2C1,
  chunk_reload_client_B1dIgHpd4m,
  plugin_4ZxH31EjIl,
  components_plugin_zlvi6dcIsi,
  prefetch_client_wQAreqkmTj,
  siteConfig_oJx4XA0JB2,
  inferSeoMetaPlugin_eONTQRbQpT,
  titles_Z2HrhC08t2,
  plugin_client_fxr5QpR3De,
  unocss_RAHspWIKy3,
  errors_hdY7q775fW,
  defaults_4dOom1KMuO
]