import routerOptions0 from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.2_db0@0.2.4_eslint@9.20.1_jiti@2.4.2__ior_2a2tyvspcsgefln7irzjunflme/node_modules/nuxt/dist/pages/runtime/router.options.js";
import routerOptions1 from "/opt/buildhome/repo/app/router.options.ts";
const configRouterOptions = {
  linkActiveClass: "",
  linkExactActiveClass: "",
  scrollBehaviorType: "smooth",
  hashMode: false
}
export const hashMode = routerOptions0.hashMode ?? false
export default {
...configRouterOptions,
...routerOptions0,
...routerOptions1,
}