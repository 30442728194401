<template>
  <div class="flex flex-col items-center justify-between gap-y-4 py-4 sm:(flex-row py-6)">
    <IcsLogo class="text-center svg:(mx-auto w-40 sm:w-auto) sm:text-left heading-link-bright">
      <component
        :is="$route.path === '/' ? 'h1' : 'div'"
        class="mt-2 flex uppercase transition-color f-text-18-22 f-leading-24-32 font-flex-520-151-528"
      >
        Integrated Cable Systems
      </component>
      <ConnectedByTrexon class="mt-2 f-max-w-170-208 sm:!mr-0" />
    </IcsLogo>
    <div class="grid grid-flow-col w-full items-center text-icsblue font-500 f-text-15-20 sm:grid-flow-row sm:w-auto dark:text-icscyan-300">
      <div class="flex items-center f-mb-4-8 f-gap-6-12 sm:justify-end">
        <div class="i-carbon-certificate sm:scale-120 bg-gradient-icsbluecyan" />
        <div class="whitespace-nowrap leading-tight">
          ISO 9001:2015 & AS9100D Certified
        </div>
      </div>
      <div class="flex items-center f-gap-6-12 sm:justify-end">
        <div class="i-carbon-phone sm:scale-120 bg-gradient-icsbluecyan" />
        <div>1-720-652-6777</div>
      </div>
      <div class="row-span-2 flex flex-wrap justify-right justify-self-end gap-x-3 sm:row-span-1 media-[(pointer:fine)]:gap-y-2 sm:f-mt-12-24">
        <ATooltip
          text="Toggle color mode"
          pos="left"
        >
          <ButtonThemeToggle
            size="fluid"
            icon-only
          />
        </ATooltip>
        <AButton
          label="Get Quote"
          color="blue"
          size="fluid"
          icon="i-carbon-task-tools"
          to="/contact/rfq"
        />
      </div>
    </div>
  </div>
</template>
