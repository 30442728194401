
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as _91_46_46_46404_93oDx7W0731TMeta } from "/opt/buildhome/repo/app/pages/[...404].vue?macro=true";
import { default as storiesfv4LYilcbhMeta } from "/opt/buildhome/repo/app/pages/about/stories.vue?macro=true";
import { default as _91tradeshow_93Nvu6J6cnOVMeta } from "/opt/buildhome/repo/app/pages/about/tradeshows/[tradeshow].vue?macro=true";
import { default as tradeshows2kfornQHJQMeta } from "/opt/buildhome/repo/app/pages/about/tradeshows.vue?macro=true";
import { default as about75bsskq4f8Meta } from "/opt/buildhome/repo/app/pages/about.vue?macro=true";
import { default as overmolded_45cables4VIpPM2YWQMeta } from "/opt/buildhome/repo/app/pages/capabilities/overmolded-cables.vue?macro=true";
import { default as terminationIY9S7EBDtwMeta } from "/opt/buildhome/repo/app/pages/capabilities/termination.vue?macro=true";
import { default as wire_45splicingPgSvQf62olMeta } from "/opt/buildhome/repo/app/pages/capabilities/wire-splicing.vue?macro=true";
import { default as capabilitiesd7mGvAYKBwMeta } from "/opt/buildhome/repo/app/pages/capabilities.vue?macro=true";
import { default as rfqGCUNj0TA4MMeta } from "/opt/buildhome/repo/app/pages/contact/rfq.vue?macro=true";
import { default as contactunl1RfU7AbMeta } from "/opt/buildhome/repo/app/pages/contact.vue?macro=true";
import { default as index9xeP2sz8ZcMeta } from "/opt/buildhome/repo/app/pages/index.vue?macro=true";
import { default as _91industry_93wJ38d5ajclMeta } from "/opt/buildhome/repo/app/pages/industries/[industry].vue?macro=true";
import { default as indexiemTG59ijyMeta } from "/opt/buildhome/repo/app/pages/industries/index.vue?macro=true";
import { default as certificationsTk4lABU9BtMeta } from "/opt/buildhome/repo/app/pages/resources/certifications.vue?macro=true";
import { default as literaturenn5rOXKfnOMeta } from "/opt/buildhome/repo/app/pages/resources/literature.vue?macro=true";
import { default as resourcesrcRgN7jHPdMeta } from "/opt/buildhome/repo/app/pages/resources.vue?macro=true";
import { default as cable_45assembliesKUO3K1AksTMeta } from "/opt/buildhome/repo/app/pages/services/cable-assemblies.vue?macro=true";
import { default as electromech_45assembliesZdWWCWUthiMeta } from "/opt/buildhome/repo/app/pages/services/electromech-assemblies.vue?macro=true";
import { default as servicesku2ObyrrRPMeta } from "/opt/buildhome/repo/app/pages/services.vue?macro=true";
import { default as component_45stubHnqy7GngyyMeta } from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.2_db0@0.2.4_eslint@9.20.1_jiti@2.4.2__ior_2a2tyvspcsgefln7irzjunflme/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubHnqy7Gngyy } from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.2_db0@0.2.4_eslint@9.20.1_jiti@2.4.2__ior_2a2tyvspcsgefln7irzjunflme/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "404",
    path: "/:404(.*)*",
    component: () => import("/opt/buildhome/repo/app/pages/[...404].vue")
  },
  {
    name: "about",
    path: "/about",
    meta: about75bsskq4f8Meta || {},
    component: () => import("/opt/buildhome/repo/app/pages/about.vue"),
    children: [
  {
    name: "about-stories",
    path: "stories",
    meta: storiesfv4LYilcbhMeta || {},
    component: () => import("/opt/buildhome/repo/app/pages/about/stories.vue")
  },
  {
    name: "about-tradeshows",
    path: "tradeshows",
    meta: tradeshows2kfornQHJQMeta || {},
    component: () => import("/opt/buildhome/repo/app/pages/about/tradeshows.vue"),
    children: [
  {
    name: "about-tradeshows-tradeshow",
    path: ":tradeshow()",
    meta: _91tradeshow_93Nvu6J6cnOVMeta || {},
    component: () => import("/opt/buildhome/repo/app/pages/about/tradeshows/[tradeshow].vue")
  }
]
  }
]
  },
  {
    name: "capabilities",
    path: "/capabilities",
    meta: capabilitiesd7mGvAYKBwMeta || {},
    component: () => import("/opt/buildhome/repo/app/pages/capabilities.vue"),
    children: [
  {
    name: "capabilities-overmolded-cables",
    path: "overmolded-cables",
    meta: overmolded_45cables4VIpPM2YWQMeta || {},
    component: () => import("/opt/buildhome/repo/app/pages/capabilities/overmolded-cables.vue")
  },
  {
    name: "capabilities-termination",
    path: "termination",
    meta: terminationIY9S7EBDtwMeta || {},
    component: () => import("/opt/buildhome/repo/app/pages/capabilities/termination.vue")
  },
  {
    name: "capabilities-wire-splicing",
    path: "wire-splicing",
    meta: wire_45splicingPgSvQf62olMeta || {},
    component: () => import("/opt/buildhome/repo/app/pages/capabilities/wire-splicing.vue")
  }
]
  },
  {
    name: "contact",
    path: "/contact",
    meta: contactunl1RfU7AbMeta || {},
    component: () => import("/opt/buildhome/repo/app/pages/contact.vue"),
    children: [
  {
    name: "contact-rfq",
    path: "rfq",
    meta: rfqGCUNj0TA4MMeta || {},
    component: () => import("/opt/buildhome/repo/app/pages/contact/rfq.vue")
  }
]
  },
  {
    name: "index",
    path: "/",
    meta: index9xeP2sz8ZcMeta || {},
    component: () => import("/opt/buildhome/repo/app/pages/index.vue")
  },
  {
    name: "industries-industry",
    path: "/industries/:industry()",
    meta: _91industry_93wJ38d5ajclMeta || {},
    component: () => import("/opt/buildhome/repo/app/pages/industries/[industry].vue")
  },
  {
    name: "industries",
    path: "/industries",
    meta: indexiemTG59ijyMeta || {},
    component: () => import("/opt/buildhome/repo/app/pages/industries/index.vue")
  },
  {
    name: "resources",
    path: "/resources",
    meta: resourcesrcRgN7jHPdMeta || {},
    component: () => import("/opt/buildhome/repo/app/pages/resources.vue"),
    children: [
  {
    name: "resources-certifications",
    path: "certifications",
    meta: certificationsTk4lABU9BtMeta || {},
    component: () => import("/opt/buildhome/repo/app/pages/resources/certifications.vue")
  },
  {
    name: "resources-literature",
    path: "literature",
    meta: literaturenn5rOXKfnOMeta || {},
    component: () => import("/opt/buildhome/repo/app/pages/resources/literature.vue")
  }
]
  },
  {
    name: "services",
    path: "/services",
    meta: servicesku2ObyrrRPMeta || {},
    component: () => import("/opt/buildhome/repo/app/pages/services.vue"),
    children: [
  {
    name: "services-cable-assemblies",
    path: "cable-assemblies",
    meta: cable_45assembliesKUO3K1AksTMeta || {},
    component: () => import("/opt/buildhome/repo/app/pages/services/cable-assemblies.vue")
  },
  {
    name: "services-electromech-assemblies",
    path: "electromech-assemblies",
    meta: electromech_45assembliesZdWWCWUthiMeta || {},
    component: () => import("/opt/buildhome/repo/app/pages/services/electromech-assemblies.vue")
  }
]
  },
  {
    name: component_45stubHnqy7GngyyMeta?.name,
    path: "/cable-assemblies",
    component: component_45stubHnqy7Gngyy
  },
  {
    name: component_45stubHnqy7GngyyMeta?.name,
    path: "/electromech-assemblies",
    component: component_45stubHnqy7Gngyy
  },
  {
    name: component_45stubHnqy7GngyyMeta?.name,
    path: "/certifications",
    component: component_45stubHnqy7Gngyy
  },
  {
    name: component_45stubHnqy7GngyyMeta?.name,
    path: "/literature",
    component: component_45stubHnqy7Gngyy
  },
  {
    name: component_45stubHnqy7GngyyMeta?.name,
    path: "/stories",
    component: component_45stubHnqy7Gngyy
  },
  {
    name: component_45stubHnqy7GngyyMeta?.name,
    path: "/tradeshows",
    component: component_45stubHnqy7Gngyy
  },
  {
    name: component_45stubHnqy7GngyyMeta?.name,
    path: "/rfq",
    component: component_45stubHnqy7Gngyy
  },
  {
    name: component_45stubHnqy7GngyyMeta?.name,
    path: "/capabilities/overmolding",
    component: component_45stubHnqy7Gngyy
  },
  {
    name: component_45stubHnqy7GngyyMeta?.name,
    path: "/products",
    component: component_45stubHnqy7Gngyy
  },
  {
    name: component_45stubHnqy7GngyyMeta?.name,
    path: "/products/products",
    component: component_45stubHnqy7Gngyy
  },
  {
    name: component_45stubHnqy7GngyyMeta?.name,
    path: "/products-mobile",
    component: component_45stubHnqy7Gngyy
  },
  {
    name: component_45stubHnqy7GngyyMeta?.name,
    path: "/products-mobile/products",
    component: component_45stubHnqy7Gngyy
  },
  {
    name: component_45stubHnqy7GngyyMeta?.name,
    path: "/company/the-trexon-family",
    component: component_45stubHnqy7Gngyy
  },
  {
    name: component_45stubHnqy7GngyyMeta?.name,
    path: "/company-mobile/the-trexon-family",
    component: component_45stubHnqy7Gngyy
  },
  {
    name: component_45stubHnqy7GngyyMeta?.name,
    path: "/ics-jobs",
    component: component_45stubHnqy7Gngyy
  }
]