export const appPageTransition = {"name":"page","mode":"out-in"}

export const appHead = {"link":[{"rel":"apple-touch-icon","sizes":"180x180","href":"/apple-touch-icon.png"},{"rel":"icon","type":"image/png","sizes":"32x32","href":"/favicon-32x32.png","media":"(prefers-color-scheme: light)"},{"rel":"icon","type":"image/png","sizes":"16x16","href":"/favicon-16x16.png","media":"(prefers-color-scheme: light)"},{"rel":"icon","type":"image/png","sizes":"32x32","href":"/favicon-32x32-dark.png","media":"(prefers-color-scheme: dark)"},{"rel":"icon","type":"image/png","sizes":"16x16","href":"/favicon-16x16-dark.png","media":"(prefers-color-scheme: dark)"},{"rel":"manifest","href":"/site.webmanifest"},{"rel":"mask-icon","href":"/safari-pinned-tab.svg","color":"#083050"}],"meta":[{"name":"viewport","content":"width=device-width, initial-scale=1"},{"charset":"utf-8"},{"name":"msapplication-TileColor","content":"#2B5797"},{"name":"theme-color","content":"#FFFFFF"},{"property":"og:type","content":"website"}],"style":[],"script":[],"noscript":[{"tagPosition":"bodyClose","innerHTML":"<aside class=\"flex-none bg-icsblue text-center text-sm text-white font-medium\"><span>Enable JavaScript on your browser for a more interactive experience.</span></aside>"}],"titleTemplate":"%s - ICS","bodyAttrs":{"class":"font-sans text-icssilver-950 selection:bg-icscyan-50/90 selection:text-black bg-white dark:bg-icsnight dark:text-icssilver-50"},"htmlAttrs":{}}

export const appRootAttrs = {"id":"__ics","class":"flex flex-col min-h-screen"}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appViewTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const appTeleportTag = "div"

export const appTeleportId = "teleports"

export const appTeleportAttrs = {"id":"teleports"}

export const appSpaLoaderTag = "div"

export const appSpaLoaderAttrs = {"id":"__nuxt-loader"}

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const cookieStore = true

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const devLogs = false

export const nuxtLinkDefaults = {"componentName":"NuxtLink","prefetch":true,"prefetchOn":{"visibility":true}}

export const asyncDataDefaults = {"deep":false}

export const resetAsyncDataToUndefined = false

export const nuxtDefaultErrorValue = undefined

export const fetchDefaults = {}

export const vueAppRootContainer = '#__ics'

export const viewTransition = false

export const appId = "nuxt-app"

export const outdatedBuildInterval = 3600000

export const multiApp = false

export const chunkErrorEvent = "vite:preloadError"

export const crawlLinks = false

export const spaLoadingTemplateOutside = true