<template>
  <svg viewBox="0 0 516.17153 49.95557">
    <g
      id="connected-by"
      class="fill-icssilver-950 dark:fill-icssilver-50"
    >
      <path d="M17.00073,40.8724c-2.39943,0-4.63398-.42218-6.70079-1.2651-2.06823-.84435-3.86781-2.01137-5.39873-3.50249s-2.73064-3.22388-3.59915-5.19688-1.30206-4.08956-1.30206-6.34969.43355-4.37527,1.30206-6.34969c.86852-1.97299,2.06823-3.70576,3.59915-5.19688s3.33049-2.65814,5.39873-3.50107c2.06681-.84435,4.30135-1.26653,6.70079-1.26653,1.64179,0,3.22815.18195,4.75907.543s2.97513.90405,4.33263,1.62616c1.3575.72353,2.58849,1.62758,3.69439,2.71216l-1.42004,1.58209c-1.35892-1.41578-3.05473-2.5231-5.09169-3.32197-2.03554-.79744-4.12794-1.1983-6.27435-1.1983-2.11514,0-4.06539.36958-5.84791,1.10732-1.78394.73916-3.34755,1.76262-4.68799,3.07321-1.34328,1.31059-2.38522,2.83298-3.1258,4.56433-.74201,1.73277-1.11301,3.60768-1.11301,5.62616s.371,3.89482,1.11301,5.62616c.74058,1.73277,1.78252,3.25373,3.1258,4.56433,1.34044,1.31059,2.90405,2.33547,4.68799,3.07321,1.78252.73774,3.73277,1.10732,5.84791,1.10732,2.14641,0,4.23881-.40654,6.27435-1.22104,2.03696-.81308,3.73277-1.91187,5.09169-3.2978l1.42004,1.58067c-1.1059,1.08458-2.33689,1.98863-3.69439,2.71216-1.3575.7221-2.80171,1.2651-4.33263,1.62616-1.53092.36247-3.11727.543-4.75907.543Z" />
      <path d="M45.98157,40.82691c-2.14641,0-4.10377-.51173-5.87207-1.5366-1.7683-1.02346-3.1656-2.41791-4.19048-4.18053-1.0263-1.76262-1.53945-3.71287-1.53945-5.85217,0-2.16916.51315-4.12651,1.53945-5.87492,1.02488-1.74556,2.42218-3.13291,4.19048-4.15637,1.7683-1.02488,3.72566-1.5366,5.87207-1.5366,2.17911,0,4.14357.51173,5.89624,1.5366,1.75267,1.02346,3.14144,2.41081,4.16774,4.15637,1.02488,1.7484,1.53803,3.70576,1.53803,5.87492,0,2.13931-.51315,4.08956-1.53803,5.85217-1.0263,1.76262-2.41507,3.15708-4.16774,4.18053-1.75267,1.02488-3.71713,1.5366-5.89624,1.5366ZM45.98157,38.92926c1.7683,0,3.37029-.42928,4.8074-1.28785,1.43568-.85857,2.57285-2.01848,3.40867-3.47975.83724-1.46127,1.25515-3.09595,1.25515-4.90406,0-1.80668-.41791-3.44137-1.25515-4.90263-.83582-1.46127-1.97299-2.62118-3.40867-3.47975-1.4371-.85857-3.03909-1.28785-4.8074-1.28785s-3.37029.42928-4.80598,1.28785c-1.4371.85857-2.57427,2.01848-3.4101,3.47975-.83724,1.46127-1.25515,3.09595-1.25515,4.90263,0,1.8081.41791,3.44279,1.25515,4.90406.83582,1.46127,1.97299,2.62118,3.4101,3.47975,1.43568.85857,3.03767,1.28785,4.80598,1.28785Z" />
      <path d="M63.83375,40.37489v-22.23314h2.08387v3.11727c1.89481-2.37953,4.46767-3.5693,7.71998-3.5693,1.76688,0,3.32197.35394,4.66383,1.06183s2.3838,1.70149,3.1258,2.98223c.74058,1.28074,1.11301,2.76475,1.11301,4.45203v14.18907h-2.08387v-13.64749c0-2.22886-.64819-3.98437-1.94172-5.26511-1.29496-1.27932-3.07889-1.9204-5.35182-1.9204-1.57783,0-2.99929.35537-4.26155,1.06183-1.26368.70931-2.25729,1.72566-2.98366,3.05047v16.7207h-2.08387Z" />
      <path d="M89.78403,40.37489v-22.23314h2.08387v3.11727c1.89481-2.37953,4.46767-3.5693,7.71998-3.5693,1.76688,0,3.32197.35394,4.66383,1.06183s2.3838,1.70149,3.1258,2.98223c.74058,1.28074,1.11301,2.76475,1.11301,4.45203v14.18907h-2.08387v-13.64749c0-2.22886-.64819-3.98437-1.94172-5.26511-1.29496-1.27932-3.07889-1.9204-5.35182-1.9204-1.57783,0-2.99929.35537-4.26155,1.06183-1.26368.70931-2.25729,1.72566-2.98366,3.05047v16.7207h-2.08387Z" />
      <path d="M126.29579,40.82691c-2.14641,0-4.10377-.51173-5.87207-1.5366-1.7683-1.02346-3.1656-2.40938-4.19048-4.15779-1.0263-1.74698-1.53945-3.70576-1.53945-5.87492s.49751-4.12651,1.49254-5.87492c.9936-1.74556,2.35963-3.13291,4.09524-4.15637,1.73561-1.02488,3.67733-1.5366,5.82517-1.5366,2.0199,0,3.81948.51173,5.39873,1.5366,1.57783,1.02346,2.83298,2.38806,3.76404,4.08956.93106,1.70149,1.3973,3.59204,1.3973,5.67165v.81308h-19.84224c.09524,1.71713.56859,3.26084,1.42147,4.63113.85146,1.37171,1.9801,2.4634,3.38593,3.27648,1.40441.8145,2.95949,1.22104,4.66383,1.22104,1.32623,0,2.6354-.20327,3.93035-.61123,1.29496-.40654,2.39943-.95522,3.31486-1.6489l1.23241,1.58209c-1.26368.8742-2.60555,1.52097-4.02559,1.94314-1.42147.42075-2.90548.63255-4.45203.63255ZM116.91981,27.90295h17.52098c-.15778-1.5366-.61549-2.92964-1.37314-4.18053-.75764-1.24947-1.73561-2.2516-2.93533-3.00498-1.20114-.75338-2.55722-1.13007-4.0725-1.13007-1.61052,0-3.07179.36105-4.38096,1.08458-1.31059.72353-2.3838,1.71002-3.21962,2.95949-.83724,1.25089-1.35039,2.67378-1.53945,4.2715Z" />
      <path d="M153.2411,40.82691c-2.14641,0-4.10377-.51173-5.87207-1.5366-1.7683-1.02346-3.1656-2.40938-4.19048-4.15779-1.0263-1.74698-1.53945-3.70576-1.53945-5.87492s.51315-4.12651,1.53945-5.87492c1.02488-1.74556,2.42218-3.13291,4.19048-4.15637,1.7683-1.02488,3.72566-1.5366,5.87207-1.5366,1.57925,0,3.1656.30135,4.75907.90405,1.59488.6027,2.99076,1.42999,4.1919,2.48472l-1.42147,1.62758c-1.01066-.96375-2.17058-1.72424-3.47975-2.28287-1.31059-.55721-2.66098-.83582-4.04976-.83582-1.7683,0-3.37029.42928-4.80598,1.28785-1.4371.85857-2.57427,2.01848-3.4101,3.47975-.83724,1.46127-1.25515,3.09595-1.25515,4.90263,0,1.8081.41791,3.44279,1.25515,4.90406.83582,1.46127,1.97299,2.62118,3.4101,3.47975,1.43568.85857,3.03767,1.28785,4.80598,1.28785,1.38877,0,2.73916-.28714,4.04976-.85857,1.30917-.57285,2.46909-1.32623,3.47975-2.26013l1.42147,1.62758c-1.20114,1.05473-2.59702,1.88202-4.1919,2.48472-1.59346.6027-3.17982.90405-4.75907.90405Z" />
      <path d="M176.20914,40.82691c-1.7683,0-3.1258-.42218-4.0725-1.2651-.94812-.84293-1.42147-2.01848-1.42147-3.52523v-16.08815h-5.11443v-1.80668h5.11443v-5.60342l2.08387-.63397v6.23739h7.1983v1.80668h-7.1983v15.41011c0,1.2651.2914,2.17769.87562,2.7349s1.55508.83582,2.91258.83582c.72637,0,1.34186-.05259,1.84791-.1592.50462-.10519,1.02488-.26297,1.56219-.47335v1.94314c-.59986.21038-1.21535.36105-1.84648.45203-.63255.08955-1.27932.13504-1.94172.13504Z" />
      <path d="M195.00802,40.82691c-2.14641,0-4.10377-.51173-5.87207-1.5366-1.7683-1.02346-3.1656-2.40938-4.19048-4.15779-1.0263-1.74698-1.53945-3.70576-1.53945-5.87492s.49751-4.12651,1.49254-5.87492c.9936-1.74556,2.35963-3.13291,4.09524-4.15637,1.73561-1.02488,3.67733-1.5366,5.82517-1.5366,2.0199,0,3.81948.51173,5.39873,1.5366,1.57783,1.02346,2.83298,2.38806,3.76404,4.08956s1.3973,3.59204,1.3973,5.67165v.81308h-19.84224c.09524,1.71713.56859,3.26084,1.42147,4.63113.85146,1.37171,1.9801,2.4634,3.38593,3.27648,1.40441.8145,2.95949,1.22104,4.66383,1.22104,1.32623,0,2.6354-.20327,3.93035-.61123,1.29496-.40654,2.39943-.95522,3.31486-1.6489l1.23241,1.58209c-1.26368.8742-2.60555,1.52097-4.02559,1.94314-1.42147.42075-2.90548.63255-4.45203.63255ZM185.63203,27.90295h17.52098c-.15778-1.5366-.61549-2.92964-1.37314-4.18053-.75764-1.24947-1.73561-2.2516-2.93533-3.00498-1.20114-.75338-2.55722-1.13007-4.0725-1.13007-1.61052,0-3.07179.36105-4.38096,1.08458-1.31059.72353-2.3838,1.71002-3.21962,2.95949-.83724,1.25089-1.35039,2.67378-1.53945,4.2715Z" />
      <path d="M221.95332,40.82691c-2.14641,0-4.10377-.51173-5.87207-1.5366-1.7683-1.02346-3.1656-2.40938-4.19048-4.15779-1.0263-1.74698-1.53945-3.70576-1.53945-5.87492s.51315-4.12651,1.53945-5.87492c1.02488-1.74556,2.42218-3.13291,4.19048-4.15637,1.7683-1.02488,3.72566-1.5366,5.87207-1.5366,1.48401,0,2.91969.27861,4.30988.83582,1.38877.55721,2.61976,1.30348,3.69297,2.23739v-12.02133l2.08387-.49751v32.13081h-2.08387v-2.66667c-2.11514,2.0796-4.78181,3.1187-8.00285,3.1187ZM221.95332,38.92926c1.73561,0,3.30775-.33973,4.71216-1.01777,1.40441-.67662,2.50178-1.57356,3.29069-2.68799v-11.93036c-.82019-1.05473-1.9403-1.93604-3.36177-2.64393-1.42004-.70789-2.96802-1.06183-4.64109-1.06183-1.7683,0-3.37029.42928-4.80598,1.28785-1.4371.85857-2.57427,2.01848-3.4101,3.47975-.83724,1.46127-1.25515,3.09595-1.25515,4.90263,0,1.8081.41791,3.44279,1.25515,4.90406.83582,1.46127,1.97299,2.62118,3.4101,3.47975,1.43568.85857,3.03767,1.28785,4.80598,1.28785Z" />
      <path d="M250.79201,40.37489V8.74159l2.08387-.49751v12.56291c2.11514-2.07818,4.78323-3.11727,8.00285-3.11727,2.17911-.02985,4.14357.47477,5.89624,1.51386,1.75267,1.03909,3.14144,2.43355,4.16774,4.17911,1.02488,1.7484,1.53803,3.70576,1.53803,5.87492,0,2.13931-.51315,4.08956-1.53803,5.85217-1.0263,1.76262-2.41507,3.15708-4.16774,4.18053-1.75267,1.02488-3.71713,1.5366-5.89624,1.5366-1.48401,0-2.91116-.27861-4.28572-.83582-1.37314-.55721-2.61265-1.30348-3.71713-2.23739v2.62118h-2.08387ZM260.87873,38.92926c1.7683,0,3.37029-.42928,4.8074-1.28785,1.43568-.85857,2.57285-2.01848,3.40867-3.47975.83724-1.46127,1.25515-3.09595,1.25515-4.90406,0-1.80668-.41791-3.44137-1.25515-4.90263-.83582-1.46127-1.97299-2.62118-3.40867-3.47975-1.4371-.85857-3.03909-1.28785-4.8074-1.28785-1.73561,0-3.30633.33831-4.71074,1.01635-1.40583.67804-2.5032,1.57498-3.29211,2.68941v11.93036c.82161,1.05473,1.94172,1.93604,3.36319,2.64393,1.42004.70789,2.9666,1.06183,4.63966,1.06183Z" />
      <path d="M278.35423,49.95557c-.34826,0-.6951-.02274-1.04193-.06823-.34826-.04549-.64819-.08387-.89979-.1123v-1.98863c.50462.1194,1.12011.18053,1.84648.18053,1.98863,0,3.55224-1.2054,4.68799-3.61479l1.89481-4.06823-9.70861-22.14217h2.36816l8.4762,19.747,8.85573-19.747h2.31983l-12.12226,26.39093c-.59986,1.29496-1.22388,2.34115-1.87065,3.14002s-1.3575,1.37882-2.13078,1.73987c-.7747.36247-1.66596.543-2.6752.543Z" />
    </g>
    <g
      id="trexon"
      class="fill-[#221f1f] dark:fill-icsgray-50"
    >
      <polygon points="317.17789 16.5238 327.98815 16.5238 327.98815 40.7388 338.10639 40.7388 338.10639 16.5238 348.91666 16.5238 348.91666 8.30804 317.17789 8.30804 317.17789 16.5238" />
      <path d="M379.96353,19.63709c0-7.22093-4.36729-11.32905-11.97786-11.32905h-15.78281v32.43076h9.94536v-10.03207h2.32757l4.98004,10.03207h10.85359v-.86517l-6.09386-10.3709c3.70947-1.68156,5.74798-5.07383,5.74798-9.86564ZM366.34263,22.75034h-4.19442v-6.22655h4.2376c2.37831,0,3.37297.90788,3.37297,3.11329s-1.03791,3.11326-3.41615,3.11326Z" />
      <polygon points="394.57874 28.19873 409.10779 28.19873 409.10779 20.24234 394.57874 20.24234 394.57874 16.5238 410.14536 16.5238 410.14536 8.30804 384.46051 8.30804 384.46051 40.7388 410.31857 40.7388 410.31857 32.43657 394.57874 32.43657 394.57874 28.19873" />
      <path d="M463.20133,7.61631c-10.37768,0-17.38269,6.70212-17.38269,16.7774,0,10.37792,6.74537,17.03681,17.20975,17.03681,10.37792,0,17.38293-6.70212,17.38293-17.03681,0-10.11854-6.70238-16.7774-17.20999-16.7774ZM463.02839,32.95535c-4.41051,0-6.91854-3.11329-6.91854-8.56164,0-5.36191,2.63772-8.30223,7.09148-8.30223,4.36729,0,6.91854,3.15651,6.91854,8.30223,0,5.53482-2.55125,8.56164-7.09148,8.56164Z" />
      <polygon points="506.31263 8.30804 506.31263 23.05297 506.22593 23.05297 488.45417 8.30804 484.64888 8.30804 484.64888 40.7388 494.50778 40.7388 494.50778 25.99332 494.59425 25.99332 512.36627 40.7388 516.17153 40.7388 516.17153 8.30804 506.31263 8.30804" />
      <polygon points="436.88588 8.30227 435.04474 8.30227 435.04069 8.30804 434.96566 8.30804 434.2105 9.49466 429.61029 16.07009 424.58774 8.30804 413.21544 8.30804 413.21544 9.17267 423.89601 24.22077 412.86962 39.87363 412.86962 40.7388 414.19295 40.7388 436.88588 8.30227" />
      <polygon points="435.71916 24.26443 446.22686 9.2533 446.22686 8.30227 445.0514 8.30227 422.34603 40.7388 424.11217 40.7388 424.11588 40.73303 424.19122 40.73303 430.02152 32.40398 435.44122 40.7388 446.81349 40.7388 446.81349 39.87363 435.71916 24.26443" />
      <polygon
        class="fill-[#e64351]"
        points="444.73841 0 410.95085 48.29482 410.95085 49.04683 414.49112 49.04683 448.27045 .79027 448.27045 0 444.73841 0"
      />
    </g>
  </svg>
</template>

<style>

</style>
